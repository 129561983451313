import { Component, OnDestroy, OnInit } from '@angular/core';
import { OperationProcessDurations } from '@lightning/lightning-definitions';

import { SettingsService } from '../../../../../shared/services/settings/settings.service';
import { DropsService } from '../../services/drops.service';

@Component({
    selector: 'app-drops-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.scss'],

})
export class SetupComponent implements OnInit, OnDestroy {

    public OperationProcessDurations = OperationProcessDurations;

    constructor(
        public dropsService: DropsService,
        private settingsService: SettingsService) { }

    public ngOnInit() {

        this.dropsService.settingsLoad();

        this.dropsService.settings.name =
            `${ new Date().toLocaleTimeString() } - ${ this.dropsService.mode }`;

        this.dropsService.settings.timeLimit =
            this.settingsService.settings.operations.timeLimit;
    }

    public  ngOnDestroy() {

        this.dropsService.settingsApply();
    }
}
