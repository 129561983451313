import { Injectable } from '@angular/core';
import { TeamUnderOperation, Products, OperationWinner, OperationTrophy, OperationActions, PmrSounds, OperationTrophiesTypes, OperatorAvatarName } from '@lightning/lightning-definitions';
import { NfcData, NfcOperatorData, NfcCashupData, NfcSpecialData } from '../../../../shared/enums/lora.enum';
import { OperationBase } from '../../shared/classes/operation.base';
import { OperationStates } from '../../shared/enums/operation.enums';
import { OperationRequierements, OperationRequierement } from '../../shared/interfaces/operation.interface';

const MAX_DELAY_TO_SIGN_A_CAPTURE = 60000;

@Injectable({
    providedIn: 'root',
})
export class PrisonersService extends OperationBase {

    constructor() {

        super('prisoners');

        // Define operation settings
        this.settings = {
            version: 1,

            name: 'Prisoners op',
            timeLimit: 1800000,
        };

        // Define organisator actions
        this.organisatorActions = [
            this.getOrganisatorActionNote()
        ];

        // Subscribe to events
        this.loraProtocolService.onNfcReceive
            .subscribe((data: NfcData) => { this.onNfcReceive(data); });

        this.loraProtocolService.onNfcOperatorReceive
            .subscribe((data: NfcOperatorData) => { this.onNfcOperatorReceive(data); });

        this.loraProtocolService.onNfcCashupReceive
            .subscribe((data: NfcCashupData) => { this.onNfcCashupReceive(data); });

        this.loraProtocolService.onNfcSpecialReceive
            .subscribe((data: NfcSpecialData) => { this.onNfcSpecialReceive(data); });

        this.registerService.onTeamsRankingLeadChanged
            .subscribe((team: TeamUnderOperation) => this.teamsRankingLeadChanged(team));
    }

    public override getRequierements(): OperationRequierements {

        const groundModules = this.loraProtocolService.getDevicesByProduct(Products.GroundModule);

        // Describing requierements
        const requierements: Array<OperationRequierement> = [
            {
                // 2 teams or more
                name: 'Count of teams',
                value: this.registerService.teams.length,
                isReady: this.registerService.teams.length >= 2,
                help: '',
            },
            {
                // One ground module per team
                name: 'Ground modules',
                value: groundModules.length.toString(),
                isReady: groundModules.length > 0 && groundModules.length >= this.registerService.teams.length,
                help: '',
            },
        ];

        // Check if all requierements are ready
        const isReady = requierements
            .some((requierement) => requierement.isReady === false) === false;

        return { requierements, isReady };
    }

    public override settingsAreValid(): boolean {

        // Each team must have a prison location
        if (this.registerService.teams.some(team => !team.data.prison)) {
            return false;
        }

        return true;
    }

    public override settingsApply(): void {

        // Timer setup
        this.timer.setCountDown(this.settings.timeLimit);

        // Save settings of the next time
        this.settingsSave();
    }

    public override getWinner(): OperationWinner | undefined {

        return this.getWinnerByTeamRank();
    }

    public override getTrophies(): Array<OperationTrophy> {

        const trophies: Array<OperationTrophy> = [];

        // Get operators with stats only
        let operators = this.registerService.operators
            .filter(operator => operator.data.stats);

        // 2 active operators or more
        if (operators.length < 2) {
            return trophies;
        }

        // First one to capture
        const firstOperator = operators
            .find(operator => operator.data.stats?.isFirst);

        if (firstOperator) {
            trophies.push({
                type: OperationTrophiesTypes.PrisonersFirstOne,
                data: {
                    operator: firstOperator
                }
            });
        }

        // Biggest total captures without equality
        operators = operators
            .sort((a, b) => (b.data.stats?.total || 0) - (a.data.stats?.total || 0));

        if((operators[0].data.stats?.total || 0) > (operators[1].data.stats?.total || 0)) {
            trophies.push({
                type: OperationTrophiesTypes.PrisonersBiggestTotal,
                data: {
                    operator: operators[0]
                }
            });
        }

        // First one to be captured
        const firstOperatorCaptured = operators
            .find(operator => operator.data.stats?.isFirstCaptured);

        trophies.push({
            type: OperationTrophiesTypes.PrisonersCapturedFirstOne,
            data: {
                operator: firstOperatorCaptured
            }
        });

        // Biggest total captured without equality
        operators = operators
            .sort((a, b) => (b.data.stats?.totalCaptured || 0) - (a.data.stats?.totalCaptured || 0));

        if((operators[0].data.stats?.totalCaptured || 0) > (operators[1].data.stats?.totalCaptured || 0)) {
            trophies.push({
                type: OperationTrophiesTypes.PrisonersCapturedBiggestTotal,
                data: {
                    operator: operators[0]
                }
            });
        }

        return trophies;
    }

    private onNfcReceive(data: NfcData): void {

        const { sender, record } = data;

        if (this.state !== OperationStates.Processing) {
            return;
        }

        // [ CUSTOM CODE HERE ]
    }

    private async onNfcOperatorReceive(data: NfcOperatorData): Promise<void> {

        const { sender, operator } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        const capturingTeam = this.registerService.teams.find(t => t.data.prison === sender.name);

        if (!capturingTeam) {

            this.environmentService.notificationOpen({
                logo: `assets/apps/operations/logo.svg`,
                message: `The area ${sender.name} is not the prison of team!`,
                color: `yellow`
            });

            return;
        }

        // Operator in its own prison
        if (capturingTeam.id === team.id) {

            // Get the last capture in this area without signature: OperatorCaptured
            const lastCapture = this.timeline
                .filter(item => item.action === OperationActions.OperatorCaptured)
                .filter(item => item.data.area === sender.name)
                .filter(item => Date.now() - item.time.getTime() < MAX_DELAY_TO_SIGN_A_CAPTURE)
                .slice(-1)[0];

            if (!lastCapture) {
                return;
            }

            // Update stats data for trophies calculations
            operator.data.stats = operator.data.stats || { isFirst: false, total: 0, isFirstCaptured: false, totalCaptured: 0 };
            operator.data.stats.isFirst = this.isFirstCaptureBy ? true : operator.data.stats.isFirst;
            operator.data.stats.total = (operator.data.stats.total || 0) + 1;

            // Updating the item as signed capture: OperatorCapturedBy
            lastCapture.action = OperationActions.OperatorCapturedBy;
            lastCapture.data.capturingOperator = operator;

            return;
        }

        // Increasing team score
        this.registerService.increaseTeamScore(capturingTeam, 1000);

        // Update stats data for trophies calculations
        operator.data.stats = operator.data.stats || { isFirst: false, total: 0, isFirstCaptured: false, totalCaptured: 0 };
        operator.data.stats.isFirstCaptured = this.isFirstCapture ? true : operator.data.stats.isFirstCaptured;
        operator.data.stats.totalCaptured = (operator.data.stats.totalCaptured || 0) + 1;

        // Fill the timeline
        this.timelinePush({
            level: 1,
            action: OperationActions.OperatorCaptured,
            data: {
                operator, team, capturingTeam, area: sender.name
            },
            customIconPath:
                this.onlineService.getOperatorAvatarPath(operator.number, OperatorAvatarName.Losing)
        });

        // Announce via PMR
        this.pmrService.announce((PmrSounds as any)[`OperatorCapturedTeam${team.name}`]);
        this.pmrService.announce((PmrSounds as any)[`TeamBy${capturingTeam.name}`]);
    }

    private async onNfcCashupReceive(data: NfcCashupData): Promise<void> {

        const { sender, operator, total } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        // [ CUSTOM CODE HERE ]
    }

    private async onNfcSpecialReceive(data: NfcSpecialData): Promise<void> {

        const { sender, operator, special } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        // [ CUSTOM CODE HERE ]
    }

    private teamsRankingLeadChanged(team: TeamUnderOperation): void {

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Announce via PMR
        this.pmrService.announce((PmrSounds as any)['TeamLeads' + team.name]);
    }

    private get isFirstCaptureBy(): boolean {
        return this.timeline
            .filter(item => item.action === OperationActions.OperatorCapturedBy)
            .length === 0;
    }

    private get isFirstCapture(): boolean {
        return this.timeline
            .filter(item => item.action === OperationActions.OperatorCaptured)
            .length === 0;
    }
}
