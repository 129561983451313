import { Component, OnInit, OnDestroy } from '@angular/core';
import { OperationProcessDurations } from '@lightning/lightning-definitions';

import { SettingsService } from '../../../../../shared/services/settings/settings.service';
import { AssemblyService } from '../../services/assembly.service';
import { RegisterService } from '@lightning/headquarter/app/apps/shared/services/register/register.service';
import { Assemblies } from '../../enums/assembly.enums';

@Component({
    selector: 'app-assembly-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.scss'],
})
export class SetupComponent implements OnInit, OnDestroy {

    public OperationProcessDurations = OperationProcessDurations;

    public Assemblies = Assemblies;

    constructor(
        public assemblyService: AssemblyService,
        public registerService: RegisterService,
        private settingsService: SettingsService
    ) {}

    public ngOnInit() {

        this.assemblyService.settingsLoad();

        this.assemblyService.settings.name =
            `${new Date().toLocaleTimeString()} - ${ this.assemblyService.mode }`;

        this.assemblyService.settings.timeLimit =
            this.settingsService.settings.operations.timeLimit;
    }

    public ngOnDestroy() {

        this.assemblyService.settingsApply();
    }

    public resetPartsNumbers(): void {

        if (this.assemblyService.settings.object === Assemblies.Custom) {
            return;
        }

        this.assemblyService.settings.partsNumbers = ['1 2 3', '4 5 6', '7 8 9', '10 11 12'];
    }
}
